<template>
  <div
    :class="{ 'loading': loadingAvailability || loadingBookRequest, 'show-date-hover': !isMidSelection }"
    class="property-availability"
  >
    <loading-spinner :loading="loadingAvailability" />
    <datepicker
      :config="dpConfig"
      class="booking-dates"
    />
    <div class="legend">
      <table style="width: 100%;">
        <tr>
          <td style="padding-left: 1rem;">
            <span class="tag is-info-light is-pulled-left">Your Bookings</span>
          </td>
          <td style="padding-left: 1rem;">
            <span class="tag is-info-light owner-guest-bg is-pulled-left">Your Guests Bookings</span>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5rem; padding-left: 1rem;">
            <span class="tag is-danger-light is-pulled-left">Holds</span>
          </td>
          <td style="padding-top: 0.5rem; padding-left: 1rem;">
            <span class="tag is-success-light is-pulled-left">Guest Bookings</span>
          </td>
        </tr>
      </table>
    </div>

    <b-message
      v-if="!hasSelection"
      type="is-info"
    >
      To make owner booking select date range above.
    </b-message>

    <form
      v-if="hasSelection"
      class="booking-form"
      role="form"
      @submit.stop.prevent="submitBooking"
    >
      <b-field
        label="Check In"
        horizontal
        grouped
        custom-class="is-small"
      >
        <p class="control is-expanded">
          {{ formatDate(checkInDate) }}
        </p>
        <p class="control">
          <button
            class="delete"
            @click.stop.prevent="dpClear"
          />
        </p>
      </b-field>

      <b-field
        label="Check Out"
        horizontal
        custom-class="is-small"
      >
        <p class="control">
          {{ formatDate(checkOutDate) }}
        </p>
      </b-field>

      <b-field
        label="Adults"
        horizontal
        custom-class="is-small"
      >
        <b-input
          v-model="adults"
          type="number"
          icon="human-male-female"
          min="1"
        />
      </b-field>

      <b-field
        label="Children"
        horizontal
        custom-class="is-small"
      >
        <b-input
          v-model="children"
          type="number"
          icon="human-child"
          min="0"
        />
      </b-field>

      <b-field
        horizontal
        custom-class="is-small"
      >
        <b-switch
          v-model="ownerBooking"
          size="is-small"
        >
          Owner Booking
        </b-switch>
      </b-field>

      <b-field
        horizontal
        custom-class="is-small"
      >
        <b-switch
          v-model="sendConfirmationEmail"
          size="is-small"
        >
          Confirmation Email
        </b-switch>
      </b-field>

      <b-field
        v-if="isOwnerGuestBooking"
        :type="{ 'is-danger': $v.guestFirstName.$error }"
        label="First Name"
        horizontal
        custom-class="is-small"
      >
        <b-input
          v-model="guestFirstName"
          icon="account-circle"
          @input="$v.guestFirstName.$touch()"
        />
      </b-field>

      <b-field
        v-if="isOwnerGuestBooking"
        :type="{ 'is-danger': $v.guestLastName.$error }"
        label="Last Name"
        horizontal
        custom-class="is-small"
      >
        <b-input
          v-model="guestLastName"
          icon="account-circle"
          @input="$v.guestLastName.$touch()"
        />
      </b-field>

      <b-field
        v-if="isOwnerGuestBooking"
        :type="{ 'is-danger': $v.guestEmail.$error }"
        :message="{ 'Must be a valid email address': !$v.guestEmail.email }"
        label="Email"
        horizontal
        custom-class="is-small"
      >
        <b-input
          v-model="guestEmail"
          icon="email"
          @input="$v.guestEmail.$touch()"
        />
      </b-field>

      <b-field
        v-if="isOwnerGuestBooking"
        label="Phone"
        horizontal
        custom-class="is-small"
      >
        <b-input
          v-model="guestPhone"
          type="tel"
          icon="cellphone-iphone"
        />
      </b-field>

      <b-field
        v-if="allowReservationValues"
        label="Booking Rate"
        horizontal
        custom-class="is-small"
        message="Total amount excluding tax"
      >
        <b-input
          v-model="guestRate"
          type="tel"
          icon="currency-usd"
        />
      </b-field>

      <b-field
        v-if="allowReservationValues"
        label="Guest Will Pay"
        horizontal
        custom-class="is-small"
        class="has-vertical-fields"
      >
        <b-radio
          v-model="pmTakesPayment"
          native-value="false"
        >
          Owner
        </b-radio>
        <b-radio
          v-model="pmTakesPayment"
          native-value="true"
        >
          Manager
        </b-radio>
      </b-field>

      <b-field
        label="Note"
        horizontal
        custom-class="is-small"
      >
        <b-input
          v-model="note"
          type="textarea"
          maxlength="1000"
          size="is-small"
          placeholder="Enter any extra info about this booking"
        />
      </b-field>

      <b-field
        v-if="fees.length"
        label="Extra Fees"
        horizontal
        custom-class="is-small"
        class="has-vertical-fields"
      >
        <b-checkbox
          v-for="fee of fees"
          :key="fee.id"
          v-model="selectedFeeIds"
          :native-value="fee.id"
        >
          {{ fee.name }} <strong>{{ formatCurrencyAmount(getFeeValue(fee)) }}</strong>
        </b-checkbox>
      </b-field>

      <b-field v-if="!propertyArchived">
        <p class="control">
          <button
            :class="{ 'is-loading': loadingBookRequest }"
            :disabled="$v.$invalid || !hasSelection"
            class="button is-success is-medium is-fullwidth"
            type="submit"
          >
            Book
          </button>
        </p>
      </b-field>
      <b-field
        v-if="propertyArchived"
        custom-class="is-small"
      >
        <p class="control">
          <b-message type="is-warning">
            Property is archived and can't be booked. If you believe this to be an error please reach out to your Property Manager.
          </b-message>
        </p>
      </b-field>
    </form>
  </div>
</template>

<script>
import { isBefore } from 'date-fns'
import addDays from 'date-fns/add_days'
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days'
import endOfMonth from 'date-fns/end_of_month'
import format from 'date-fns/format'
import isSameDay from 'date-fns/is_same_day'
import {
  find,
  isNil
} from 'lodash'
import Raven from 'raven-js'
import Datepicker from 'vue-bulma-datepicker'
import LoadingSpinner from 'vue-spinner/src/MoonLoader'
import { validationMixin } from 'vuelidate'
import {
  required, email
} from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import ApiGateway from '@/services/api-gateway'
import { getErrorMessage } from '@/services/helpers'

export default {
  name: 'PropertyAvailability',

  components: {
    LoadingSpinner,
    Datepicker
  },

  mixins: [validationMixin],

  props: {
    companyId: {
      type: String,
      default: ''
    },
    propertyId: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: ''
    },
    fees: {
      type: Array,
      default () { return [] }
    },
    propertyArchived: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loadingAvailability: true,
      loadingBookRequest: false,
      dpMonth: new Date(),
      preloadDays: 40, // Preload days from next/prev month.
      checkIn: null,
      checkInDate: null,
      lastNight: null,
      nights: null,
      checkOutDate: null,
      adults: 2,
      children: 0,
      ownerBooking: true,
      sendConfirmationEmail: false,
      guestFirstName: null,
      guestLastName: null,
      guestEmail: null,
      guestPhone: null,
      pmTakesPayment: 'false',
      guestRate: null,
      note: '',
      availabilityRecords: [],
      dpConfig: {
        mode: 'range',
        inline: true,
        minDate: 'today',
        disable: [this.dateIsUnavailable],
        onReady: this.dpReady,
        onMonthChange: this.dpMonthChange,
        onYearChange: this.dpMonthChange,
        onDayCreate: this.dpDayCreate,
        onChange: this.dpChange
      },
      selectedFeeIds: []
    }
  },

  validations () {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!this.isOwnerGuestBooking) return {}
    return {
      guestFirstName: { required },
      guestLastName: { required },
      guestEmail: {
        required,
        email
      }
    }
  },

  computed: {
    hasSelection () {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return Boolean(this.checkIn && this.lastNight)
    },

    isMidSelection () {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return Boolean(this.checkIn && !this.lastNight)
    },

    isOwnerGuestBooking () {
      return this.ownerBooking === false
    },

    propertyManagerTakesPayment () {
      return this.pmTakesPayment === 'true'
    },

    selectedFees () {
      return this.selectedFeeIds.map((feeId) => find(this.fees, fee => fee.id === feeId))
    },

    allowReservationValues () {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return Boolean(this.isOwnerGuestBooking && this.activeCompany.reservation_values)
    },

    ...mapGetters([
      'activeCompany',
      'lastCancelledPropertyItem'
    ])
  },

  watch: {
    async lastCancelledPropertyItem (item) {
      if (item.property_id === this.propertyId) await this.refreshAvailability()
    },

    ownerBooking (isOwnerBooking) {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      this.sendConfirmationEmail = !isOwnerBooking
    },

    hasSelection (hasSelectionDone) {
      if (hasSelectionDone === true) {
        const dates = this.dpSelectedDates()
        // if the last date has disabled checout then clear the selection
        const selectedCheckout = this.dateAvailability(dates[1])
        if (selectedCheckout?.disable_check_out === true) {
          this.dpClear()
        }
      }
    }
  },

  async created () {
    await this.getAvailability(new Date())
  },

  methods: {
    async getAvailability (date) {
      this.loadingAvailability = true
      try {
        const companyId = this.companyId
        const userId = this.userId
        const propertyId = this.propertyId
        const start = format(addDays(date, -(this.preloadDays)), 'YYYY-MM-DD')
        const end = format(addDays(endOfMonth(date), this.preloadDays), 'YYYY-MM-DD')
        const queryParams = {
          start,
          end
        }
        const response = await ApiGateway.invokeApi({
          method: 'GET',
          pathTemplate: '/companies/{companyId}/owners/{userId}/properties/{propertyId}/availability',
          params: {
            companyId,
            userId,
            propertyId
          },
          additionalParams: { queryParams }
        })
        const days = response.data
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const filter = (day) => Boolean(day.status) ||
          day.disable_check_in === true ||
          day.disable_check_out === true ||
          day.early_check_in === true ||
          day.check_out === true ||
          day.late_check_out === true

        this.availabilityRecords = days.filter(filter)
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (this.datepicker) {
          this.datepicker.redraw()
          this.datepicker.jumpToDate(this.dpMonth)
        }
      } catch (e) {
        Raven.captureException(e)
        this.$buefy.toast.open({
          message: `Error loading property availability - ${getErrorMessage(e)}`,
          type: 'is-danger'
        })
      }
      this.loadingAvailability = false
    },

    async refreshAvailability () {
      this.getAvailability(this.dpMonth)
    },

    async dpMonthChange (selectedDates, value, datepicker) {
      const year = datepicker.currentYear
      const month = datepicker.currentMonth
      this.dpMonth = new Date(year, month)
      await this.getAvailability(this.dpMonth)
    },

    dpReady (selectedDates, value, datepicker) {
      this.datepicker = datepicker
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!this.$el) return
      // Append the legend inside the datepicker because it looks cleaner.
      this.datepicker.calendarContainer.appendChild(this.$el.querySelector('.legend'))
    },

    dpDayCreate (selectedDates, value, datepicker, el) {
      // Wrap date value in a span
      el.innerHTML = `<label>${el.innerHTML}</label>`
      const availability = this.dateAvailability(el.dateObj)
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!availability) { return }
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (availability.check_in) el.classList.add('is-check-in')
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (availability.last_night) el.classList.add('is-last-night')
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (availability.check_out) el.classList.add('is-check-out')
      if (availability.status === 'hold') el.classList.add('is-hold')
      if (availability.status === 'booked') el.classList.add('is-booked')
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (availability.owner_booking) el.classList.add('is-owner-booking')
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (availability.owner_guest_booking) el.classList.add('is-owner-guest-booking')
      if (availability.disable_check_in === true) el.classList.add('disable-check-in')
      if (availability.disable_check_out === true) el.classList.add('disable-check-out')
    },

    dpChange (selectedDates, value, datepicker) {
      this.checkInDate = selectedDates[0]
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      this.checkIn = this.checkInDate && format(this.checkInDate, 'YYYY-MM-DD')
      this.checkOutDate = selectedDates[1]
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (this.checkInDate && this.checkOutDate && isSameDay(this.checkInDate, this.checkOutDate)) {
        const nextDay = addDays(this.checkOutDate, 1)
        if (!isNil(this.dateAvailability(nextDay)) && Boolean(this.dateAvailability(nextDay)?.early_check_in)) {
          this.dpClear()
          return
        }

        this.checkOutDate = addDays(this.checkOutDate, 1)
        datepicker.selectedDates[1] = this.checkOutDate
        datepicker.redraw()
      }
      // calculate number of nights using `differenceInCalendarDays`
      this.nights = differenceInCalendarDays(this.checkOutDate, this.checkInDate)
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      this.lastNight = this.checkOutDate && format(addDays(this.checkOutDate, -1), 'YYYY-MM-DD')
    },

    dpClear () {
      this.datepicker.clear()
    },

    dpSelectedDates () {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!this.datepicker?.selectedDates) return []
      return this.datepicker.selectedDates
    },

    dateAvailability (date) {
      const dateString = format(date, 'YYYY-MM-DD')
      return find(this.availabilityRecords, d => d.date === dateString)
    },

    dateIsUnavailable (date) {
      const selectedDates = this.dpSelectedDates()
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (selectedDates.some(d => isSameDay(d, date))) return false
      if (this.hasSelection !== true && selectedDates.some(d => !Boolean(isBefore(d, date))) === true) return true
      const availability = this.dateAvailability(date)
      if (availability == null) return false
      if ((availability.disable_check_in === true || availability.disable_check_out === true)) {
        if (selectedDates.length > 0) {
          if (availability.disable_check_in === true) {
            return false
          } else {
            return false
          }
        } else {
          if (availability.disable_check_in === true) {
            return true
          } else {
            return false
          }
        }
      }

      // only let user choose existing check-in as their check-out date
      if (selectedDates.length === 1) {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (availability.early_check_in) return true
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (availability.check_out) return true
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        return !availability.check_in
      }

      if (availability.early_check_in === true) return true
      if (availability.late_check_out === true) return true

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return Boolean(availability.status) || !availability.check_out
    },

    formatDate (date) {
      return format(date, 'MMM Do, YYYY')
    },

    async submitBooking () {
      this.loadingBookRequest = true
      try {
        const propertyId = this.propertyId
        const start = this.checkIn
        const end = this.lastNight
        const adults = Number(this.adults)
        if (!(adults > 0)) {
          throw new Error('Trips requires at least one adult.')
        }
        const children = Number(this.children)
        const ownerGuestBooking = this.isOwnerGuestBooking
        const fees = this.selectedFees
        const note = this.note
        const propertyItem = {
          start,
          end,
          adults,
          children,
          ownerGuestBooking,
          note
        }
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (ownerGuestBooking) {
          const firstName = this.guestFirstName
          const lastName = this.guestLastName
          const email = this.guestEmail
          const phone = this.guestPhone
          const allowResValues = this.allowReservationValues
          propertyItem.guest = {
            firstName,
            lastName,
            email,
            phone
          }
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          propertyItem.pmTakesPayment = allowResValues ? this.propertyManagerTakesPayment : true
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          propertyItem.rate = allowResValues ? this.guestRate : 0
        }
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (fees.length) propertyItem.fees = fees
        propertyItem.sendConfirmationEmail = this.sendConfirmationEmail
        await this.$store.dispatch('bookPropertyItem', {
          propertyId,
          propertyItem
        })
        await this.getAvailability(this.dpMonth)
        this.dpClear()
        this.selectedFeeIds = []
        this.$buefy.toast.open({
          message: 'Booking submitted successfully',
          type: 'is-success'
        })
      } catch (e) {
        Raven.captureException(e)
        this.$buefy.toast.open({
          message: `Property booking error - ${getErrorMessage(e)}`,
          type: 'is-danger'
        })
      }
      this.loadingBookRequest = false
    },

    getFeeValue (fee) {
      let feeValue = fee.value
      if (Boolean(fee.use_property_values)) {
        feeValue = Boolean(fee.property_values[this.propertyId]) ? fee.property_values[this.propertyId] : fee.value
      }
      if (!(Boolean(feeValue))) {
        feeValue = 0
      }
      if (Boolean(fee.is_nightly)) {
        feeValue = feeValue * this.nights
      }
      return feeValue
    },

    formatCurrencyAmount (amount) {
      return this.$store.getters.formattedCurrencyAmount(amount)
    }
  }
}
</script>

<style lang="sass">
@import "../assets/styles/variables.sass"

$width: 296px // Overall width of the calendar
$day-dimensions: ceil($width/7)
$selection-color: transparentize($blue, 0.5)

@mixin unavailable-day
 content: ' '
 position: absolute
 top: 0
 right: 0
 bottom: 0
 left: 0
 z-index: 0

@mixin check-in($color)
  transform: skew(-45deg)
  right: floor(-$day-dimensions)
  left: floor($day-dimensions/2) - 1
  background: $color
  border-left: 1px solid white

@mixin check-out($color)
  transform: skew(-45deg)
  right: floor($day-dimensions/2) + 1
  left: floor(-$day-dimensions)
  background: $color

@mixin disabled-check-in($color)
  background: linear-gradient(-45deg, rgba(0,0,0,0) 50%, $color 50%), repeating-linear-gradient(-45deg, $danger-light, $danger-light 5px, white 5px, white 10px)
@mixin disabled-check-out($color)
  background: linear-gradient(-45deg, $color 50%, rgba(255,255,255,0) 50%), repeating-linear-gradient(-45deg, $danger-light, $danger-light 5px, white 5px, white 10px)

.owner-guest-bg
  background: $owner-guest-green !important

.property-availability
  position: relative
  width: $width
  margin: 0 auto

  &.loading
    .flatpickr-calendar,
    .message
      opacity: 0.3
      pointer-events: none

  .v-spinner
    position: absolute
    z-index: 1000
    left: 130px
    top: 110px

  .selected-dates
    width: 100%

  input.booking-dates
    display: none

  // Set the width of the callendar
  .flatpickr-calendar,
  .flatpickr-weekdays,
  .flatpickr-days,
  .dayContainer
    width: $width
    min-width: $width

  .flatpickr-calendar
    margin: 0 auto 2em

    // Reset default Flatpickr day styles
    .flatpickr-day
      border: none
      border-radius: 0
      background: none
      box-shadow: none
      margin-top: 1px!important
      overflow: hidden

      &.today:not(.selected):not(.disabled),
      &.today:not(.selected):not(.disabled):hover,
      &.today:not(.selected):not(.disabled):focus
        color: $text
        border: none

      &.prevMonthDay,
      &.nextMonthDay
        &:not(.disabled):not(.notAllowed)
          color: rgba(0,0,0,0.4)

    &.arrowTop
      &:before,
      &:after
        display: none

    .legend
      padding: 10px 0 5px

      .tag
        margin-right: 5px

        &:last-child
          margin-right: 0

  .flatpickr-day
    width: $day-dimensions
    height: $day-dimensions
    line-height: $day-dimensions

    label
      position: relative
      z-index: 1
      pointer-events: none

    &.is-hold,
    &.is-booked,
    &.is-owner-booking
      &:after
        @include unavailable-day

    &.is-check-out
      &:before
        @include unavailable-day

    &.is-hold
      &:after
        background: $danger-light

      &.is-check-in
        &:after
          @include check-in($danger-light)

      & + .is-check-out
        &:before
          @include check-out($danger-light)

    &.is-booked
      &:after
        background: $success-light

      &.is-check-in
        &:after
          @include check-in($success-light)

      & + .is-check-out
        &:before
          @include check-out($success-light)

    &.is-owner-booking
      &:after
        background: $info-light

      &.is-check-in
        &:after
          @include check-in($info-light)

      & + .is-check-out
        &:before
          @include check-out($info-light)

    &.is-owner-guest-booking
      &:after
        background: $owner-guest-green

      &.is-check-in
        &:after
          @include check-in($owner-guest-green)

      & + .is-check-out
        &:before
          @include check-out($owner-guest-green)

    &.disable-check-in
        @include disabled-check-in(rgba(255,255,255,1))

    &.disable-check-out
        @include disabled-check-out(rgba(255,255,255,1))


    &.selected.startRange,
    &.startRange.startRange,
    &.endRange.startRange
      & + .endRange
        box-shadow: none

    &.startRange,
    &.selected.startRange,
    &.startRange.inRange,
    &.endRange,
    &.selected.endRange,
    &.endRange.inRange,
    &.selected,
    &.prevMonthDay.inRange,
    &.nextMonthDay.inRange,
    &.prevMonthDay.endRange,
    &.nextMonthDay.endRange,
    &.inRange
      color: $text
      background: none

      &:hover,
      &:focus
        background: none
        color: $text

    &.startRange,
    &.selected.startRange
      &.disable-check-out
        @include disabled-check-out(rgba(255,255,255,1))
      &:after
        @include unavailable-day
        @include check-in($selection-color)

    &.endRange,
    &.selected.endRange
      &.disable-check-in
        @include disabled-check-in(rgba(255,255,255,1))
      &:before
        @include unavailable-day
        @include check-out($selection-color)

    &.inRange:not(.startRange):not(.endRange)
      &:before
        @include unavailable-day
        background: $selection-color

    &.selected:not(.startRange):not(.endRange):not(.inRange):after
      @include unavailable-day
      background: $selection-color

  .field-label
    flex-grow: 3

  &.show-date-hover
    .flatpickr-day:not(.disabled):hover
      background: rgba(0,0,0,0.05)
      &.disable-check-in
        @include disabled-check-in(rgb(242,242,242))
      &.disable-check-out
        @include disabled-check-out(rgb(242,242,242))
</style>
